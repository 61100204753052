import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Logo from "./assets/images/logo.png";

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <div className="flex flex-col w-full h-screen">
      <div className="w-full px-4 py-2 h-30 border-b-gray-500">
        <Link to="/">
          <img src={Logo} className="h-20" alt="logo" />
        </Link>
      </div>
      <div className="flex-grow w-full overflow-y-scroll">{children}</div>
    </div>
  );
}
