import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { encode } from "base-64";

export default function Login() {
  const [code, setCode] = useState<string>("");
  const navigate = useNavigate();

  const handleChange = (value: string) => {
    if (isNaN(parseInt(value)) && value !== "") return;

    setCode(value);
  };

  const encoded = encode(code);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="w-1/3 mx-auto">
        <h1 className="mb-4 text-center">Palun sisestage oma isikukood, et jätkata</h1>
        <input
          className="w-full h-10 px-4 py-2 mb-6 text-center border border-gray-500 rounded-md"
          type="text"
          maxLength={11}
          value={code}
          onChange={({ target: { value } }) => handleChange(value)}
        />
        <div className="flex justify-center">
          <button
            className="px-4 py-2 mx-auto text-white bg-gray-500 border border-gray-500 rounded-md disabled:cursor-not-allowed disabled:opacity-50"
            disabled={code.length !== 11}
            onClick={() => navigate(`/${encoded}`)}
          >
            SISENE
          </button>
        </div>
      </div>
    </div>
  );
}
